import React from 'react'
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

function Contact() {
  return (
    <>
      <Layout type="contact-us" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lenx.ai</title>
      </Helmet>
    </>
  )
}

export default Contact
